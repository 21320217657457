import type { FrontendUrlMode } from '@repo/shared-config/environment';

import ENV from 'qonto/config/environment';

type QontentApiConfig = {
  BASE_PATH: string;
  HOST: string;
  TOKEN: string;
};

const { frontendUrlMode: environment } = ENV;

const qontentApi: Record<FrontendUrlMode, QontentApiConfig> = {
  development: {
    BASE_PATH: '/api/resources',
    HOST: 'https://qa-account-connect-api-23d29887defa.herokuapp.com/',
    TOKEN: '7d9e996b41f52699ef3cd8aa96c2c9a5',
  },
  staging: {
    BASE_PATH: '/api/resources',
    HOST: 'https://qa-account-connect-api-23d29887defa.herokuapp.com/',
    TOKEN: '7d9e996b41f52699ef3cd8aa96c2c9a5',
  },
  production: {
    BASE_PATH: '/api/resources',
    HOST: 'https://qonto.com',
    TOKEN: 'cbee950b664616b41c22968f4daf9507',
  },
};

export const { BASE_PATH, HOST, TOKEN } = qontentApi[environment];
